.pinBoxWrapper {
  position: relative;
  width: 340px;
  margin: 0 auto 0.1em;
}

.pinBoxLabel {
  font-size: 18px;
  color: var(--silver);
  display: block;
  margin-bottom: 20px;
}

.pinBox {
  display: flex;
  flex-flow: column;
  position: relative;
  padding-bottom: 5px;
}
.pinBox:after {
  content: '';
  margin-left: -3rem;
}

.pinEntry {
  position: relative;
  width: calc(100% + 50px);
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  outline: none;
  color: var(--silver);
  line-height: 1.1;
  font-family: var(--digital7mono-font);
  letter-spacing: 3.5rem;
  font-size: 80px;
  caret-color: transparent;
}

.pinEntry + span {
  height: 5px;
  margin-bottom: 20px;
  background-image: linear-gradient(
    90deg,
    var(--silver),
    var(--silver) 70%,
    transparent 70%,
    transparent 100%
  );
  background-size: 5.7rem 1px;
  opacity: 0.6;
}

.pinEntry:focus + span {
  opacity: 1;
}

.pinEntry:valid {
  color: var(--silver);
}

.pinEntry:invalid {
  color: red;
}

.pinEntry:invalid + span {
  background-image: linear-gradient(90deg, red, red 70%, transparent 70%, transparent 100%);
}

.pinBox button {
  font-size: 3rem;
  color: white;
  border: 0;
  background: transparent;
  border: 2px solid var(--silver);
}

.pinBox button:disabled {
  opacity: 0.6;
}

@media screen and (min-width: 545px) {
  .pinBoxWrapper {
    width: 380px;
  }

  .pinEntry {
    letter-spacing: 4.4rem;
  }

  .pinEntry + span {
    background-size: 6.4rem 1px;
  }
}

@media screen and (min-width: 765px) {
  .pinEntry {
    letter-spacing: 4.4rem;
  }
}
