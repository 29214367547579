header {
  padding-top: 3rem;
}

.body-wrap {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  overflow: hidden;
  background: url(../assets/images/bg_game.jpg) no-repeat top/cover;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 400;
}

main {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  font-size: 8vw;
}

.main__logo {
  display: block;
  position: relative;
  width: 50rem;
  margin: 0 auto;
}

.main__logo img {
  margin: auto;
}
.main__title {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.3em;
  font-weight: 700;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.main__title ~ .main__text {
  font-size: 0.25em;
  line-height: 1.25em;
  color: #c7edff;
  font-weight: 400;
  margin-bottom: 1em;
}
.main__text {
  text-align: center;
  font-size: 0.175em;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.main__btn-block {
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0.7em;
  position: relative;
}
.main__btn {
  background: url(../assets/images/button.png) no-repeat 50%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  display: inline-block;
  margin: 0;
  font-weight: 700;
  padding: 0.7em 2.5em 0.8em;
  font-size: 0.17em;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.main__btn--disable {
  opacity: 0;
  pointer-events: none;
}
.main__btn--disable ~ .main__btn-text {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 5s;
  -moz-transition-delay: 5s;
  transition-delay: 5s;
}
.main__btn--hide-text ~ .main__btn-text {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.main__btn:hover {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
}
.main__btn-text {
  position: absolute;
  left: 0;
  top: 10%;
  width: 100%;
  text-align: center;
  font-size: 0.37em;
  font-weight: 700;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #6fd611;
}
.main__btn-text .text-grad {
  text-align: center;
  font-family: Sports World, sans-serif;
  font-weight: 400;
  display: block;
  text-transform: uppercase;
}
.main__btn-text .text-grad:after {
  content: '';
  background: url(../assets/images/line_yellow.png) no-repeat 50%;
  -moz-background-size: 100% auto;
  background-size: 100% auto;
  position: absolute;
  width: 12em;
  height: 100%;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 70%);
  -moz-transform: translate(-50%, 70%);
  -ms-transform: translate(-50%, 70%);
  transform: translate(-50%, 70%);
}
.main__btn-text .text-grad:before {
  content: attr(data-text);
  position: absolute;
  width: 100%;
  text-align: center;
  display: block;
  color: transparent;
  text-shadow: 0 0 0.51em rgba(232, 166, 1, 0.5);
  -webkit-text-stroke: 0.03em #f1c700;
}
.main__btn-text .text-grad span {
  position: relative;
  display: block;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, #e39400),
    color-stop(25%, #e39400),
    color-stop(40%, #eebf00),
    color-stop(45%, #f1c700),
    color-stop(64%, #da7100),
    color-stop(95%, #e08700),
    to(#da7100)
  );
  background: -moz-linear-gradient(
    top,
    #e39400 20%,
    #e39400 25%,
    #eebf00 40%,
    #f1c700 45%,
    #da7100 64%,
    #e08700 95%,
    #da7100
  );
  background: linear-gradient(
    180deg,
    #e39400 20%,
    #e39400 25%,
    #eebf00 40%,
    #f1c700 45%,
    #da7100 64%,
    #e08700 95%,
    #da7100
  );
  color: transparent;
  padding-bottom: 0.1em;
  -webkit-background-clip: text;
  background-clip: text;
}
@media (max-width: 1500px) {
  main {
    font-size: 9vw;
  }
}
@media (max-width: 1024px) {
  main {
    font-size: 11vw;
  }
}
@media (max-width: 768px) {
  main {
    font-size: 20vw;
  }
  header .main__logo {
    width: 20rem;
  }
  main .main__text {
    width: 60%;
    margin: 0 auto 1em;
  }
  main .main__title {
    width: 14em;
    margin: 0.5em auto;
  }
  main .main__title ~ .main__text {
    width: 24em;
    font-size: 0.165em;
    margin: 0 auto 1em;
  }
}
@media (min-aspect-ratio: 32/15) and (orientation: landscape) {
  .main--game {
    font-size: 7vw;
  }
  .main--game .main__logo {
    width: 5.25em;
  }
  .main--game .main__text {
    width: auto;
    margin: 0 auto 1em;
  }
  .main--game .main__title {
    width: auto;
    margin: 0.5em auto;
  }
  .main--game .main__title ~ .main__text {
    width: auto;
  }
}
@media (min-aspect-ratio: 12/5) and (orientation: landscape) {
  .main--game {
    font-size: 6vw;
  }
}
@media (min-aspect-ratio: 48/17) and (orientation: landscape) {
  .main--game {
    font-size: 5vw;
  }
}
@media (min-aspect-ratio: 24/7) and (orientation: landscape) {
  .main--game .main__logo {
    width: 4.5em;
    margin: 0.1em auto 0;
  }
  .main--game .main__title {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
}
@media (min-aspect-ratio: 568/565) and (orientation: landscape) and (max-width: 1024px) {
  .main--game {
    font-size: 14vw;
  }
  .main--game .main__logo {
    width: 4.25em;
    margin: 0.1em auto 0;
  }
  .main--game .main__text {
    width: auto;
    margin: 0 auto 1em;
  }
  .main--game .main__title {
    width: auto;
    margin: 0.2em auto;
  }
  .main--game .main__title ~ .main__text {
    width: 90%;
  }
}
@media (min-aspect-ratio: 568/565) and (orientation: landscape) and (max-width: 1024px) {
  .main--game {
    font-size: 12vw;
  }
  .main--game .main__btn-block {
    margin-bottom: 0.6em;
  }
}
@media (min-aspect-ratio: 71/45) and (orientation: landscape) and (max-width: 1024px) {
  .body-wrap {
    overflow-y: auto;
  }
  .main--game {
    font-size: 10vw;
  }
}
.logo_light {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-animation: a 1.5s ease-in-out infinite;
  -moz-animation: a 1.5s ease-in-out infinite;
  animation: a 1.5s ease-in-out infinite;
}
.logo_light:last-child {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.audio,
.plyr {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
@-webkit-keyframes a {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes a {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes a {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
.blue {
  color: #c7edff;
}
.pop {
  font-size: 8vw;
}
.pop__wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
}
.pop__wrap.active {
  opacity: 1;
  visibility: visible;
}
.pop__content {
  background: url(../assets/images/pop.png) no-repeat 50%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  color: #fff;
  padding: 0.65em 0 0.2em;
  max-width: 3.25em;
  margin: auto;
  position: relative;
  width: 100%;
  text-align: center;
}
.pop__title {
  font-size: 0.235em;
  color: #6fd611;
  text-align: center;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 400;
  margin-bottom: 2.8em;
}
.pop__btns {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pop__btn {
  font-size: 0.15em;
  width: 6.8em;
  padding: 0.8em 0;
  margin: 0 0.2em;
  background: url(../assets/images/pop_btn.png) no-repeat 50%;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  cursor: pointer;
}
.pop--win .pop__title {
  font-weight: 700;
  color: #fff;
  margin-bottom: 2.6em;
}
.pop--win .pop__title--sup {
  color: #6fd611;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 400;
  font-size: 0.2em;
  text-align: center;
  margin-bottom: 0.1em;
}
.pop--win .pop__btn {
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 1500px) {
  .pop {
    font-size: 9vw;
  }
}
@media (max-width: 1024px) {
  .pop {
    font-size: 11vw;
  }
}
@media (max-width: 768px) {
  .pop {
    font-size: 80px;
  }
}

.box {
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 8vw;
  width: 7.2em;
  margin: 0 auto 0.2em;
  height: 2.3em;
  position: relative;
}
.box__absolute {
  width: 33.333%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.box__absolute--0 {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.box__absolute--1 {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
.box__absolute--2 {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  transform: translateX(200%);
}

@media (max-width: 1500px) {
  .box {
    font-size: 9vw;
  }
}
@media (max-width: 1024px) {
  .box {
    font-size: 11vw;
  }
}
@media (max-width: 768px) {
  .box {
    font-size: 14vw;
    width: 6.7em;
  }
}
@media (min-aspect-ratio: 192/85) and (orientation: landscape) {
  .box {
    font-size: 7vw;
  }
}
@media (min-aspect-ratio: 8/3) and (orientation: landscape) {
  .box {
    font-size: 6vw;
  }
}
@media (min-aspect-ratio: 16/5) and (orientation: landscape) {
  .box {
    font-size: 5vw;
  }
}
@media (min-aspect-ratio: 96/25) and (orientation: landscape) {
  .box {
    font-size: 4vw;
  }
}
@media (min-aspect-ratio: 142/125) and (orientation: landscape) and (max-width: 1024px) {
  .box {
    font-size: 13vw;
  }
}
@media (min-aspect-ratio: 71/50) and (orientation: landscape) and (max-width: 1024px) {
  .box {
    font-size: 11vw;
  }
}
@media (min-aspect-ratio: 568/315) and (orientation: landscape) and (max-width: 1024px) {
  .box {
    font-size: 9vw;
  }
}
@media (min-aspect-ratio: 71/35) and (orientation: landscape) and (max-width: 1024px) {
  .box {
    font-size: 7vw;
  }
}
