.box__block {
  width: 1.88em;
  margin: auto;
  position: relative;
  cursor: pointer;
}
.box__disable {
  pointer-events: none;
}

.box__block img {
  width: 100%;
}

.box__shadow {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 224%;
  transform: translate(-50%, 35%);
}

.box__back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.box__front {
  position: relative;
}

.box__glow__under {
  position: absolute;
  width: 157%;
  left: 50%;
  top: -17%;
  transform: translateX(-48%);
}

.box__glow__under img {
  opacity: 0;
  transform: translateY(10%);
}

.box__glow__k {
  position: absolute;
  width: 92%;
  left: 50%;
  top: 17%;
  transform: translateX(-48%);
}

.box__glow__k img {
  opacity: 0;
}

.box__top {
  top: 0;
  opacity: 0;
  transform: translateY(-30%);
}

.box__sum,
.box__top {
  position: absolute;
  left: 0;
}

.box__sum {
  top: 10%;
  font-family: Sports World, sans-serif;
  font-size: 0.51em;
  transform: translateY(-130%);
}

.box__sum,
.box__sum:before {
  width: 100%;
  text-align: center;
  display: block;
}

.box__sum:before {
  content: attr(data-text);
  position: absolute;
  color: transparent;
  text-shadow: 0 0 0.51em #4b8e62;
  -webkit-text-stroke: 0.05em #bddffe;
}

.box__sum span {
  position: relative;
  display: block;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, #377580),
    color-stop(45%, #9cc4e5),
    color-stop(57%, #fff),
    color-stop(62%, #fff),
    color-stop(64%, #698fa8),
    color-stop(95%, #21426a),
    to(#00283b)
  );
  background: -moz-linear-gradient(
    top,
    #377580 25%,
    #9cc4e5 45%,
    #fff 57%,
    #fff 62%,
    #698fa8 64%,
    #21426a 95%,
    #00283b
  );
  background: linear-gradient(
    180deg,
    #377580 25%,
    #9cc4e5 45%,
    #fff 57%,
    #fff 62%,
    #698fa8 64%,
    #21426a 95%,
    #00283b
  );
  color: transparent;
  padding-bottom: 0.1em;
  background-clip: text;
  -webkit-background-clip: text;
}

.box__down .box__top {
  animation: b 0.5s ease-in-out forwards;
  animation-delay: 0.5s;
}
.box__down .box__sum {
  animation: c 0.5s ease-in-out forwards;
  animation-delay: 0.2s;
}
.box__down .box__block {
  animation: h 3s ease-in-out infinite;
  animation-delay: 5s;
}
.box__down .box__absolute:first-child .box__block {
  height: 500px;
  animation-delay: 5s;
}
.box__down .box__absolute:nth-child(2) .box__block {
  animation-delay: 6s;
}
.box__down .box__absolute:nth-child(3) .box__block {
  animation-delay: 5.5s;
}

.box__block_up .box__top {
  animation: d 0.5s ease-in-out both;
  animation-delay: 0.2s;
}
.box__block_up .box__sum {
  animation: e 0.5s ease-in-out both;
  animation-delay: 0.3s;
}
.box__block_up .box__glow-under img {
  animation: f 0.5s ease-in-out both;
  animation-delay: 0.3s;
}
.box__block_up .box__glow-k img {
  animation: g 0.5s ease-in-out forwards;
  animation-delay: 0.3s;
}

@keyframes b {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes c {
  0% {
    transform: translateY(-130%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes d {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-40%);
  }
}
@keyframes e {
  0% {
    transform: translateY(0);
  }
  to {
    transform: translateY(-130%);
  }
}

@keyframes f {
  0% {
    opacity: 0;
    transform: translateY(10%) scale(0.7);
  }
  to {
    opacity: 1;
    transform: translateY(-10%) scale(1);
  }
}

@keyframes g {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes h {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
