@font-face {
  font-family: Impact;
  src: local('Impact'), url(./Impact.woff2) format('woff2'), url(./Impact.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roboto Condensed;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
    url(./RobotoCondensed-Bold.woff2) format('woff2'),
    url(./RobotoCondensed-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Digital-7 Mono;
  src: local('Digital-7 Mono'), local('Digital-7Mono'), url(./Digital-7Mono.woff2) format('woff2'),
    url(./Digital-7Mono.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roboto Condensed;
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
    url(./RobotoCondensed-Regular.woff2) format('woff2'),
    url(./RobotoCondensed-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Sports World;
  src: local('Sports World'), local('SportsWorld'), url(./SportsWorld.woff2) format('woff2'),
    url(./SportsWorld.woff) format('woff');
  font-weight: 400;
  font-style: normal;
}

:root {
  --impact-font: Impact;
  --roboto-font: Roboto Condensed;
  --digital7mono-font: Digital-7 Mono;
  --sports-world: Sports World;
}
